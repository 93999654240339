@font-face {
font-family: '__myriadPro_0a91cf';
src: url(/_next/static/media/fe2d082e3e809282-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__myriadPro_0a91cf';
src: url(/_next/static/media/0f9f605ce0b96bbe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__myriadPro_0a91cf';
src: url(/_next/static/media/6cf96abb12457c29-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__myriadPro_Fallback_0a91cf';src: local("Arial");ascent-override: 107.44%;descent-override: 27.31%;line-gap-override: 0.00%;size-adjust: 94.47%
}.__className_0a91cf {font-family: '__myriadPro_0a91cf', '__myriadPro_Fallback_0a91cf'
}

@font-face {
font-family: '__myriadProCondensed_85a248';
src: url(/_next/static/media/b08ab8b839e727ab-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__myriadProCondensed_85a248';
src: url(/_next/static/media/21a6a93eb17705e2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__myriadProCondensed_Fallback_85a248';src: local("Arial");ascent-override: 116.73%;descent-override: 29.67%;line-gap-override: 0.00%;size-adjust: 86.95%
}.__className_85a248 {font-family: '__myriadProCondensed_85a248', '__myriadProCondensed_Fallback_85a248'
}.__variable_85a248 {--font-condensed: '__myriadProCondensed_85a248', '__myriadProCondensed_Fallback_85a248'
}

